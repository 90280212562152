import React, { useState, useEffect } from "react";
import "../styles/ItemList.css";

function ItemList({
  onItemClick,
  selectedItem,
  selectedHashtags = [],
  refreshTrigger,
}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    async function fetchItems() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/items`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setItems(data);
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchItems();
  }, [refreshTrigger]);

  const handleItemClick = (item) => {
    onItemClick(item);
  };

  const filteredItems =
    selectedHashtags.length > 0
      ? items.filter((item) =>
          selectedHashtags.every((hashtag) =>
            item.hashtags.map((h) => h._id).includes(hashtag._id)
          )
        )
      : items;

  const getPriorityClassName = (priority) => {
    const defaultPriority = "P2 - Medium";
    const finalPriority = priority || defaultPriority;
    return `item-priority-${finalPriority.split(" ")[0]}`;
  };

  const getStatusClassName = (status) => {
    const defaultStatus = "New";
    const finalStatus = status || defaultStatus;
    switch (finalStatus) {
      case "New":
        return "item-status-new";
      case "In progress (Assigned)":
        return "item-status-in-progress";
      case "Fixed (Untested)":
        return "item-status-fixed-untested";
      case "Fixed (Tested OK)":
        return "item-status-fixed-tested";
      case "Obsolete (Not relevant)":
        return "item-status-obsolete";
      default:
        return "";
    }
  };

  return (
    <>
      <h3>Backlog</h3>
      <div className="item-list-container">
        <ul className="item-list">
          {filteredItems.map((item) => {
            const priorityClass = getPriorityClassName(item.priority);
            const statusClass = getStatusClassName(item.status);

            return (
              <li
                key={item.itemId}
                onClick={() => handleItemClick(item)}
                className={`item ${
                  selectedItem && selectedItem.itemId === item.itemId
                    ? "selected"
                    : ""
                }`}
              >
                <div className={`item-card ${priorityClass} ${statusClass}`}>
                  <p className="title-tag">
                    [{item.priority ? item.priority.split(" ")[0] : "P2"}]{" "}
                    {item.name}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default ItemList;
