import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ItemList from "../Tasks/sub-components/ItemList";
import ItemComponent from "../Tasks/sub-components/ItemComponent";
import ActionPanel from "../Actions/ActionPanel";
import ForHashtagComponentAddItem from "./ForHashtagComponentAddItem";

import Logout from "../Login/Logout";
import UserProfile from "../Login/UserProfile";
import { useSelector } from "react-redux";
import "../MainPage/MobileMainComponent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { copyToClipboard } from "../../utils/clipboardUtils";
import "bootstrap-icons/font/bootstrap-icons.css";

function HasMobileComponent() {
  const { id } = useParams(); // id would be '00003,00005'
  const [selectedItem, setSelectedItem] = useState(null);
  const [refreshItemList, setRefreshItemList] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [items, setItems] = useState([]);
  const [dateComment, setDateComment] = useState("");
  const [selectedHashtags, setSelectedHashtags] = useState([]);
  const [currentSection, setCurrentSection] = useState("section1");
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const navigate = useNavigate();
  const handleHashtagSelection = (hashtags) => {
    if (!hashtags || hashtags.includes(undefined)) {
      setSelectedHashtags([process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID]);
      localStorage.setItem(
        "selectedHashtags",
        JSON.stringify([process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID])
      );
    } else {
      setSelectedHashtags(hashtags);
      localStorage.setItem("selectedHashtags", JSON.stringify(hashtags));
    }
  };
  useEffect(() => {
    const fetchHashtags = async () => {
      try {
        const response = await fetch(`/api/hashtags/details?ids=${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch hashtags");
        }
        const data = await response.json();
        setSelectedHashtags(data);
      } catch (error) {
        console.error("Error fetching hashtags:", error);
      }
    };

    if (id) {
      fetchHashtags();
    }
  }, [id]);

  const handleDateSelection = (startDate, endDate) => {
    const formattedStartDate = startDate.toLocaleString();
    const formattedEndDate = endDate.toLocaleString();
    setDateComment(
      `Selected date range: Start - ${formattedStartDate}, End - ${formattedEndDate}`
    );
  };

  const handleNewItemAdded = () => {
    setRefreshItemList((prev) => !prev);
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch(`/api/tasks/by-hashtags?hashtags=${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch tasks");
        }
        const data = await response.json();
        setItems(data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, [id, refreshItemList]);

  const fetchItem = async (itemId) => {
    try {
      const response = await fetch(`/api/items/${itemId}`);
      if (response.ok) {
        const item = await response.json();
        setSelectedItem(item);
      }
    } catch (error) {
      console.error("Error fetching item:", error);
    }
  };

  const refreshSelectedItem = () => {
    if (selectedItem && selectedItem.itemId) {
      fetchItem(selectedItem.itemId);
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const onItemUpdated = (updatedItem) => {
    setSelectedItem(updatedItem);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.itemId === updatedItem.itemId ? updatedItem : item
      )
    );
    setRefreshItemList((prev) => !prev);
  };
  const sections = [
    <div className="HashtagPanel">
      <ForHashtagComponentAddItem
        selectedHashtags={selectedHashtags}
        onItemAdded={handleNewItemAdded}
        setSelectedHashtags={handleHashtagSelection}
      />
    </div>,
    <ItemList
      onItemClick={handleItemClick}
      selectedItem={selectedItem}
      selectedHashtags={selectedHashtags}
      refreshTrigger={refreshItemList}
    />,

    <ItemComponent
      item={selectedItem}
      refreshItem={refreshSelectedItem}
      onItemUpdated={onItemUpdated}
      dateComment={dateComment}
    />,
    <ActionPanel selectedItem={selectedItem} onItemUpdated={onItemUpdated} />,
    ,
  ];

  const nextSection = () => {
    setCurrentSectionIndex((prev) =>
      prev < sections.length - 1 ? prev + 1 : prev
    );
  };

  const previousSection = () => {
    setCurrentSectionIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  return (
    <>
      <div className="top-navbar">
        <Link to="/">
          <div className="logo">
            <img src="/logo.png" alt="Logo" />
          </div>
        </Link>
        <Link to="/" className="app-title-link">
          <div className="app-title">Pioneer</div>
        </Link>
        <div className="hashtag-section">
          <div className="selected-hashtags">
            {Array.isArray(selectedHashtags) &&
              selectedHashtags.map((hashtag, index) => (
                <p key={index}>{hashtag.tag}</p>
              ))}
          </div>
        </div>
        <div className="logout-button">
          <Logout />
        </div>
        <div>
          <UserProfile />
        </div>
      </div>
      <div className="mobile-layout">
        <div className="navigation-bar">
          <button onClick={previousSection}>&lt;</button>
          <button onClick={nextSection}>&gt;</button>
        </div>
        <div className="section-display">{sections[currentSectionIndex]}</div>
      </div>
    </>
  );
}

export default HasMobileComponent;
