// import React from "react";
// import { Dropdown } from "react-bootstrap";

// const UserProfile = () => {
//   const handleLogout = () => {
//     localStorage.removeItem("userToken");
//     localStorage.removeItem("username");
//     localStorage.removeItem("isUserLoggedIn");
//     window.location.href = "/Login";
//   };

//   const username = localStorage.getItem("username");
//   const initials = username
//     ?.toUpperCase()
//     .split(" ")
//     .map((name) => name[0])
//     .join("");

//   return (
//     <Dropdown align="end">
//       <Dropdown.Toggle id="dropdown-user">
//         <div
//           style={{
//             width: "40px",
//             height: "40px",
//             borderRadius: "50%",
//             backgroundColor: "#007bff",
//             color: "gray",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           {initials}
//         </div>
//       </Dropdown.Toggle>

//       <Dropdown.Menu>
//         <Dropdown.Item onClick={() => (window.location.href = "/Logout")}>
//           Profile
//         </Dropdown.Item>
//         <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

// export default UserProfile;

import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import "./UserProfile.css";

const UserProfile = () => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("username");
    localStorage.removeItem("isUserLoggedIn");
    window.location.href = "/login"; // Make sure the path is correct for your app's routing
  };

  const username = localStorage.getItem("username");
  const initials = username
    ?.toUpperCase()
    .split(" ")
    .map((name) => name[0])
    .join("");

  return (
    <div
      className="menu-container"
      ref={menuRef}
      style={{ position: "fixed", top: "10px", right: "10px", zIndex: 1000 }}
    >
      <div
        className="menu-trigger"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#007bff",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer", // Change cursor to pointer when hovering over the initials
          }}
        >
          {initials}
        </div>
      </div>

      <div
        className={`dropdown-menu ${open ? "active" : "inactive"}`}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "0.5rem",
          border: "1px solid rgba(0, 0, 0, 0.125)",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
          padding: "0.5rem",
        }}
      >
        <h3 className="userNa">{username}</h3>
        <hr />

        {/* <DropdownItem
            text="Profile"
            onClick={() => (window.location.href = "/Logout")}
          /> */}
        <DropdownItem text="Logout" onClick={handleLogout} />
      </div>
    </div>
  );
};

function DropdownItem({ text, onClick }) {
  return (
    <li className="dropdownItem" onClick={onClick}>
      <a> {text} </a>
    </li>
  );
}

export default UserProfile;
