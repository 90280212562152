import { combineReducers } from "redux";
import {
  ADD_COMMENT_SUCCESS,
  SET_START_DATE,
  SET_END_DATE,
  LOGOUT,
  ADD_DATE_COMMENT,
} from "./actions";

// Initial state for comments reducer
const initialCommentsState = {
  commentsByItemId: {},
};

// Comments reducer
const commentsReducer = (state = initialCommentsState, action) => {
  switch (action.type) {
    case ADD_COMMENT_SUCCESS:
      const { itemId, comment } = action.payload;
      return {
        ...state,
        commentsByItemId: {
          ...state.commentsByItemId,
          [itemId]: [...(state.commentsByItemId[itemId] || []), comment],
        },
      };
    default:
      return state;
  }
};

// Initial state for date range reducer
const initialDateRangeState = {
  start: null,
  end: null,
  comments: [],
};

// Date range reducer
const dateRangeReducer = (state = initialDateRangeState, action) => {
  switch (action.type) {
    case SET_START_DATE:
      return {
        ...state,
        start: action.payload,
      };
    case SET_END_DATE:
      return {
        ...state,
        end: action.payload,
      };
    case ADD_DATE_COMMENT:
      return { ...state, comments: [...state.comments, action.payload] };
    default:
      return state;
  }
};

// Auth reducer
const authInitialState = {
  isLoggedIn: false,
};

const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
const initialState = {
  hashtags: [],
  selectedHashtags: [],
};

const hashtagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_HASHTAGS":
      return {
        ...state,
        selectedHashtags: action.payload,
      };
    default:
      return state;
  }
};
// Combine reducers
const rootReducer = combineReducers({
  comments: commentsReducer,
  auth: authReducer,
  dateRange: dateRangeReducer,
  hashtags: hashtagsReducer,
});

export default rootReducer;
