import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setStartDate, setEndDate, addDateComment } from "../../redux/actions";

const ETAComponent = ({ itemId }) => {
  const [localStartDate, setLocalStartDate] = useState(null);
  const [localEndDate, setLocalEndDate] = useState(null);
  const dispatch = useDispatch();

  const reduxState = useSelector((state) => state);
  console.log("Complete Redux State:", reduxState);

  const datesState = useSelector(
    (state) => state.dates || { startDate: null, endDate: null }
  );
  const { startDate, endDate } = datesState;

  console.log("Dates from Redux:", datesState);

  useEffect(() => {
    if (localStartDate && localStartDate !== startDate) {
      dispatch(setStartDate(localStartDate));
      dispatch(
        addDateComment({
          text: `Start date set: ${localStartDate.toLocaleDateString()}`,
          postedBy: "System",
          postedAt: new Date(),
        })
      );
    }
  }, [localStartDate, startDate, dispatch]);

  useEffect(() => {
    if (localEndDate && localEndDate !== endDate) {
      dispatch(setEndDate(localEndDate));
      dispatch(
        addDateComment({
          text: `End date set: ${localEndDate.toLocaleDateString()}`,
          postedBy: "System",
          postedAt: new Date(),
        })
      );
    }
  }, [localEndDate, endDate, dispatch]);

  return (
    <>
      <div className="priority-box">
        <label>Start Date:</label>
        <DatePicker
          selected={localStartDate}
          onChange={date => setLocalStartDate(date)}
        />
      </div>
      <div className="priority-box">
        <label>End Date:</label>
        <DatePicker
          selected={localEndDate}
          onChange={date => setLocalEndDate(date)}
        />
      </div>
    </>
  );
};

export default ETAComponent;
