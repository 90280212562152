import React, { useState } from "react";
import "../../Hashtags/styles/HashtagAndItemManager.css";

function AddItem({ selectedHashtags, onItemAdded }) {
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [errors, setErrors] = useState({});
  const username = localStorage.getItem("username");

  const validateForm = () => {
    const newErrors = {};
    if (!itemName.trim()) newErrors.itemName = "Task name is required.";
    if (!itemDescription.trim())
      newErrors.itemDescription = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    const hashtagsText =
      selectedHashtags.length > 0
        ? selectedHashtags
            .map(
              (ht) =>
                `${ht.tag} <a href='/hashtags/${ht.customId}'>(Hashtag Id: ${ht.customId})</a>`
            )
            .join(", ")
        : `Uncategorized (Hashtag Id: ${process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID})`;

    const newItem = {
      name: itemName,
      description: itemDescription,
      priority: "P2 - Medium",
      status: "Open",

      assignee: "unassigned",
      hashtags:
        selectedHashtags.length > 0
          ? selectedHashtags.map((ht) => ht._id)
          : [process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID],

      comments: [
        {
          text: `Task created with hashtags: ${hashtagsText}`,
          type: "log",
          postedBy: username,
          postedAt: new Date(),
        },
      ],
    };

    console.log("New Item being sent:", newItem);

    const apiUrl = `${process.env.REACT_APP_API_URL}/items`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newItem),
      });

      if (!response.ok) {
        throw new Error("Failed to add new item");
      }

      const data = await response.json();
      setItemName("");
      setItemDescription("");
      setErrors({});
      onItemAdded(); // Trigger re-fetching of tasks
    } catch (error) {
      console.error("Error:", error);
      setErrors({ form: "Failed to add the task. Please try again." });
    }
  };

  return (
    <div className="add-item-container">
      <h3>Add Tasks</h3>
      {errors.form && <p className="error-message">{errors.form}</p>}
      <form onSubmit={handleSubmit} className="add-item-form">
        <div className="form">
          <input
            type="text"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            placeholder="New Task"
            className={errors.itemName ? "error" : ""}
          />
          {errors.itemName && <p style={{ color: "red" }}>{errors.itemName}</p>}
          <input
            type="text"
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            placeholder="Description"
            className={errors.itemDescription ? "error" : ""}
          />
          {errors.itemDescription && (
            <p style={{ color: "red" }}>{errors.itemDescription}</p>
          )}
          <button className="button-fullwidth" type="submit">
            Add Task
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddItem;
