import React from "react";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import "./CommentList.css";

function CommentList({ itemId, comments }) {
  console.log("CommentList props:", comments);

  function createMarkup(htmlContent) {
    if (!htmlContent) return { __html: "" };

    const sanitizedContent = DOMPurify.sanitize(htmlContent, {
      ALLOWED_TAGS: [
        "br",
        "p",
        "ul",
        "li",
        "ol",
        "b",
        "i",
        "u",
        "strike",
        "blockquote",
        "a",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "span",
        "img",
      ],
      ALLOWED_ATTRS: { "*": ["style", "class", "href", "src", "alt"] },
    });

    const brokenText = sanitizedContent.replace(/([^><]+)(?=<|$)/g, (match) => {
      return match.replace(/(.{10})(?=.)/g, "$1<wbr>");
    });

    return { __html: brokenText };
  }

  function formatDate(dateStr) {
    if (!dateStr) return "Invalid Date";
    const date = new Date(dateStr);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  const commentsFromStore = useSelector(
    (state) => state.comments[itemId] || []
  );

  const safeComments = comments || commentsFromStore;

  return (
    <div className="comments-container">
      {safeComments.map((comment, index) => (
        <div key={comment._id || index} className="comment">
          <small className="comment-serial-number">comment #{index + 1}</small>
          <div
            className="comment-content"
            dangerouslySetInnerHTML={createMarkup(comment.text)}
          />
          <small>
            {comment.postedBy || "Anonymous"} on {formatDate(comment.postedAt)}
          </small>
        </div>
      ))}
    </div>
  );
}

export default CommentList;

// import React from "react";
// import { useSelector } from "react-redux"; // Import useSelector
// import DOMPurify from "dompurify";
// import "./CommentList.css";

// function CommentList({ itemId, comments }) {
//   console.log("CommentList props:", comments);

//   function createMarkup(htmlContent) {
//     if (!htmlContent) return { __html: "" }; // Handles undefined or null content gracefully

//     // Sanitize first to prevent any malformed HTML from causing issues
//     const sanitizedContent = DOMPurify.sanitize(htmlContent, {
//       ALLOWED_TAGS: ["br", "p"], // Specify allowed tags
//     });

//     // Breaking long strings not within HTML tags to prevent layout issues
//     const brokenText = sanitizedContent.replace(/([^><]+)(?=<|$)/g, (match) => {
//       return match.replace(/(.{10})(?=.)/g, "$1<wbr>"); // Insert word breaks
//     });

//     return { __html: brokenText };
//   }

//   function formatDate(dateStr) {
//     if (!dateStr) return "Invalid Date";
//     const date = new Date(dateStr);
//     return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
//   }

//   // Retrieve comments from Redux store if not provided as a prop
//   const commentsFromStore = useSelector(
//     (state) => state.comments[itemId] || []
//   );

//   // Use comments from props if available, otherwise use comments from store
//   const safeComments = comments || commentsFromStore;

//   return (
//     <div className="comments-container">
//       {safeComments.map((comment, index) => (
//         <div key={comment._id || index} className="comment">
//           <small className="comment-serial-number">comment #{index + 1}</small>
//           <div
//             className="comment-content"
//             dangerouslySetInnerHTML={createMarkup(comment.text)}
//           />
//           <small>
//             {comment.postedBy || "Anonymous"} on {formatDate(comment.postedAt)}
//           </small>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default CommentList;
