import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ItemComponent from "../Tasks/sub-components/ItemComponent";
import ActionPanel from "../Actions/ActionPanel";
import { fetchItem } from "../Tasks/services/ItemServices";
import "./ItemDetail.css";
// import "./App.css";
import Logout from "../Login/Logout";
import UserProfile from "../Login/UserProfile";
const ItemDetail = ({ onItemUpdated }) => {
  const { itemId } = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetchItem(itemId)
      .then((data) => {
        setItem(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching item:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [itemId]);

  if (loading) {
    return <div>Loading item...</div>;
  }

  if (error) {
    return <div>Error loading item: {error}</div>;
  }

  // Handle item update for both ItemComponent and ActionPanel
  const handleItemUpdated = (updatedItem) => {
    setItem(updatedItem);
    if (onItemUpdated) {
      onItemUpdated(updatedItem);
    }
  };

  return (
    <>
      <div className="top-navbar">
        <Link to="/">
          <div className="logo">
            <img src="/logo.png" alt="Logo" />
          </div>
        </Link>
        <Link to="/" className="app-title-link">
          <div className="app-title">Pioneer</div>
        </Link>
        <div className="logout-button">
          <Logout />
        </div>
        <div>
          <UserProfile />{" "}
        </div>
      </div>

      <>
        {item ? (
          <>
            <div className="SidePanels">
              <ItemComponent item={item} onItemUpdated={handleItemUpdated} />
            </div>
            <div className="ActionPanel">
              <ActionPanel
                selectedItem={item}
                onItemUpdated={handleItemUpdated}
              />
            </div>
          </>
        ) : (
          <div>Item not found or failed to load.</div>
        )}
      </>
    </>
  );
};

export default ItemDetail;
