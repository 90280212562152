// File: src/services/HashtagService.js
import { apiRequest } from "../../../utils/apiUtils";
import axios from "axios";
const endpointBase = "/hashtags";
const groupEndpointBase = "/hashtag-groups";

export const fetchHashtags = () => apiRequest(endpointBase);
export const fetchHashtagByCustomId = (customId) =>
  apiRequest(`${endpointBase}/${customId}`);
export const createHashtag = (hashtagData) =>
  apiRequest(endpointBase, "POST", hashtagData);
export const updateHashtag = (customId, hashtagData) => {
  const updatedData = { ...hashtagData, customId }; // Ensure customId is included in the body
  return apiRequest(`${endpointBase}/${customId}`, "PUT", updatedData);
};

// export const updateHashtag = (customId, hashtagData) => apiRequest(`${endpointBase}/${customId}`, 'PUT', hashtagData);
export const deleteHashtag = (id) =>
  apiRequest(`${endpointBase}/${id}`, "DELETE");

export const fetchHashtagsByGroup = (groupId) => {
  if (!groupId) {
    console.error("fetchHashtagsByGroup was called with undefined groupId");
    return Promise.reject(new Error("GroupId is undefined"));
  }
  const url = `${endpointBase}?group=${groupId}`;
  return apiRequest(url) // This already returns JSON data; no need to parse it again
    .catch((error) => {
      console.error(`Error fetching hashtags for group ID ${groupId}:`, error);
      throw new Error(`Failed to fetch hashtags: ${error.message}`);
    });
};

export const fetchUngroupedHashtags = () => {
  const url = `${endpointBase}?group=none`; // Ensure this matches your backend query handling logic
  return apiRequest(url)
    .then((response) => response)
    .catch((error) => {
      console.error("Error fetching ungrouped hashtags:", error);
      throw new Error(`Failed to fetch ungrouped hashtags: ${error.message}`);
    });
};

export const fetchHashtagGroups = () => apiRequest(groupEndpointBase);
export const fetchHashtagGroupById = (groupId) =>
  apiRequest(`${groupEndpointBase}/${groupId}`);
export const createHashtagGroup = (groupData) => {
  if (!groupData || !groupData.name) {
    console.error("Invalid group data for creation:", groupData);
    return Promise.reject(new Error("Invalid group data provided"));
  }
  return apiRequest("/hashtag-groups", "POST", groupData).catch((error) => {
    console.error(`Error creating hashtag group:`, error);
    throw new Error(`Failed to create hashtag group: ${error.message}`);
  });
};
export const updateHashtagGroup = (groupId, groupData) =>
  apiRequest(`${groupEndpointBase}/${groupId}`, "PUT", groupData);
export const deleteHashtagGroup = (groupId) =>
  apiRequest(`${groupEndpointBase}/${groupId}`, "DELETE");
export const assignHashtagToGroups = async ({ hashtagId, groups }) => {
  try {
    // Ensure the hashtagId is correctly appended to the URL
    const response = await axios.patch(
      `/api/hashtags/${hashtagId}/assign-groups`,
      { groups }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error in assignHashtagToGroups function:",
      error.response?.data || error.message
    );
    throw new Error(
      error.response?.data?.message || "Failed to assign hashtag to groups"
    );
  }
};
