import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import commentsReducer from "./reducer";
import { thunk as thunkMiddleware } from "redux-thunk";
// import rootReducer from "./reducer";
// Enhancers and middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  // Combine reducers here
  comments: commentsReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))

  // Apply other middleware if needed
);

export default store;
