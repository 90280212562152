import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom"; // Only Router needed here
import { Provider } from 'react-redux';
import store from './redux/store';
import AppRoutes from './components/MainPage/AppRoutes';
import "./components/MainPage/App.css";

function App() {
    // State management and hooks like useState, useEffect, etc.
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    // Other state and logic if needed for App level

    useEffect(() => {
        const checkLoginStatus = () => {
            const loggedIn = localStorage.getItem("isUserLoggedIn") === "true";
            setIsUserLoggedIn(loggedIn);
        };

        checkLoginStatus();
        window.addEventListener("storage", checkLoginStatus);
        return () => window.removeEventListener("storage", checkLoginStatus);
    }, []);

    // Component rendering logic...
    return (
        <Provider store={store}>
            <Router>
                <div className="App">
                    <AppRoutes isUserLoggedIn={isUserLoggedIn} />
                </div>
            </Router>
        </Provider>
    );
}

export default App;
