import React, { useState } from "react";
import { Form, Button, Container, Card, Alert } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  //const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors
    axios
      .post(`/api/signup`, {
        username,
        email,
        password,
      })
      .then((response) => {
        console.log(response.data);
        window.location.href = "/login"; // Navigate to the login page
      })
      .catch((error) => {
        if (error.response) {
          console.error("Response error:", error.response);
          setError(
            error.response.data.message ||
              "Something went wrong with the response"
          );
        } else if (error.request) {
          console.error("Request error:", error.request);
          setError(
            "The request was made but no response was received. This usually indicates a network error, such as a timeout, dropped connection, or the server is not reachable."
          );
        } else if (error.message === "Network Error") {
          console.error("Network error:", error.message);
          setError(
            "A network error occurred. This could be due to a loss of connection or the server being unreachable."
          );
        } else {
          console.error("Error message:", error.message);
          setError("An error occurred: " + error.message);
        }
      });
  };

  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };

  const cardStyle = {
    width: "400px",

    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const inputStyle = {
    height: "45px",
    fontSize: "1em",
  };

  const buttonStyle = {
    width: "100%",
    backgroundColor: "#007bff",
    borderColor: "#007bff",
  };

  return (
    <div style={wrapperStyle}>
      <Card style={cardStyle}>
        <Card.Body>
          <h3 className="text-center mb-4">Sign Up</h3>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your username"
                onChange={(e) => setUsername(e.target.value)}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Button type="submit" className="mt-4" style={buttonStyle}>
              Sign Up
            </Button>
            <div className="text-center mt-3">
              <p>Already have an account?</p>
              <Link to="/login" className="btn btn-light w-100">
                Login
              </Link>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SignUp;
