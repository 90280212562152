//hashtagManagers.
import React, { useState, useEffect } from "react";
import * as HashtagService from "../services/HashtagService";
import "../styles/HashtagAndItemManager.css";
import Modal from "../../CommonElements/Modal";
import SettingsPanel from "./HashtagSettingsPanel";

function HashtagManager({ setExternalSelectedHashtags }) {
  const [hashtags, setHashtags] = useState([]);
  const [hashtagGroups, setHashtagGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [uiSelectedHashtags, setUiSelectedHashtags] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all hashtag groups and the hashtags of the selected group
  const fetchData = async () => {
    try {
      const fetchedGroups = await HashtagService.fetchHashtagGroups();
      setHashtagGroups(fetchedGroups || []);
      if (fetchedGroups.length > 0 && !selectedGroup) {
        setSelectedGroup(fetchedGroups[0].customId);
        fetchHashtags(fetchedGroups[0].customId); // Fetch hashtags for the first group
      }
    } catch (err) {
      setError("Failed to fetch groups: " + err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchHashtags = async (groupId) => {
    if (!groupId) return;
    try {
      const fetchedHashtags = await HashtagService.fetchHashtagsByGroup(
        groupId
      );
      setHashtags(fetchedHashtags || []);
    } catch (err) {
      setError("Failed to fetch hashtags: " + err.message);
    }
  };

  const handleGroupSelection = (event) => {
    const groupId = event.target.value;
    setSelectedGroup(groupId);
    fetchHashtags(groupId); // Fetch hashtags for the selected group immediately
  };

  const handleSelectHashtag = (hashtag) => {
    setUiSelectedHashtags((prev) => {
      const index = prev.findIndex((h) => h.customId === hashtag.customId);
      if (index >= 0) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      } else {
        return [...prev, hashtag];
      }
    });
  };

  const handleNewGroupAdded = (newGroup) => {
    console.log("New group added:", newGroup);
    setHashtagGroups((prevGroups) => [...prevGroups, newGroup]);
    setSelectedGroup(newGroup.customId);
    fetchHashtags(newGroup.customId);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setError(null);

    // Fetch hashtags for the selected group when the modal is closed
    if (!isModalOpen) {
      fetchHashtags(selectedGroup);
    }
  };

  return (
    <div className="hashtag-manager-container">
      <h3>Hashtags</h3>
      {error && <p className="error">{error}</p>}
      <button className="button-linkstyle" onClick={toggleModal}>
        Settings
      </button>
      {isModalOpen && (
        <Modal onClose={toggleModal}>
          <SettingsPanel
            onGroupAdded={handleNewGroupAdded}
            hashtagGroups={hashtagGroups}
            hashtags={hashtags}
            setHashtags={setHashtags}
            selectedGroup={selectedGroup}
            setError={setError}
            fetchHashtagGroups={fetchData}
          />
        </Modal>
      )}
      <div className="org-selector">
        <label htmlFor="org-select">Org:</label>
        <select
          id="org-select"
          value={selectedGroup || ""}
          onChange={handleGroupSelection}
        >
          {hashtagGroups.map((group) => (
            <option key={group.customId} value={group.customId}>
              {group.name}
            </option>
          ))}
        </select>
      </div>
      <div className="group-selector">
        <label htmlFor="group-select">Team:</label>
        <select
          id="group-select"
          value={selectedGroup || ""}
          onChange={handleGroupSelection}
        >
          {hashtagGroups.map((group) => (
            <option key={group.customId} value={group.customId}>
              {group.name}
            </option>
          ))}
        </select>
      </div>
      <ul className="hashtag-list">
        {hashtags.length > 0 ? (
          hashtags.map(
            (hashtag) =>
              hashtag.tag && (
                <li
                  key={hashtag.customId}
                  className={`hashtag-item ${
                    uiSelectedHashtags.includes(hashtag) ? "selected" : ""
                  }`}
                  onClick={() => handleSelectHashtag(hashtag)}
                >
                  {hashtag.tag}
                </li>
              )
          )
        ) : (
          <p>No hashtags available</p> // Display this if there are no hashtags
        )}
      </ul>
    </div>
  );
}

export default HashtagManager;

// import React, { useState, useEffect } from "react";
// import * as HashtagService from "../services/HashtagService";
// import "../styles/HashtagAndItemManager.css";
// import Modal from "../../CommonElements/Modal";
// import SettingsPanel from "./HashtagSettingsPanel";

// function HashtagManager({ setExternalSelectedHashtags }) {
//   const [hashtags, setHashtags] = useState([]);
//   const [hashtagGroups, setHashtagGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState(null);
//   const [uiSelectedHashtags, setUiSelectedHashtags] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [error, setError] = useState(null);

//   // Fetch all hashtag groups and the hashtags of the selected group
//   const fetchData = async () => {
//     try {
//       const fetchedGroups = await HashtagService.fetchHashtagGroups();
//       setHashtagGroups(fetchedGroups || []);
//       if (fetchedGroups.length > 0 && !selectedGroup) {
//         setSelectedGroup(fetchedGroups[0].customId);
//         fetchHashtags(fetchedGroups[0].customId); // Fetch hashtags for the first group
//       }
//     } catch (err) {
//       setError("Failed to fetch groups: " + err.message);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchHashtags = async (groupId) => {
//     if (!groupId) return;
//     try {
//       const fetchedHashtags = await HashtagService.fetchHashtagsByGroup(
//         groupId
//       );
//       setHashtags(fetchedHashtags || []);
//     } catch (err) {
//       setError("Failed to fetch hashtags: " + err.message);
//     }
//   };

//   const handleGroupSelection = (event) => {
//     const groupId = event.target.value;
//     setSelectedGroup(groupId);
//     fetchHashtags(groupId); // Fetch hashtags for the selected group immediately
//   };

//   const handleSelectHashtag = (hashtag) => {
//     setUiSelectedHashtags((prev) => {
//       const index = prev.findIndex((h) => h.customId === hashtag.customId);
//       if (index >= 0) {
//         return [...prev.slice(0, index), ...prev.slice(index + 1)];
//       } else {
//         return [...prev, hashtag];
//       }
//     });
//   };

//   const handleNewGroupAdded = (newGroup) => {
//     console.log("New group added:", newGroup);
//     setHashtagGroups((prevGroups) => [...prevGroups, newGroup]);
//     setSelectedGroup(newGroup.customId);
//     fetchHashtags(newGroup.customId);
//   };

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//     setError(null);

//     // Fetch hashtags for the selected group when the modal is closed
//     if (!isModalOpen) {
//       fetchHashtags(selectedGroup);
//     }
//   };

//   return (
//     <div className="hashtag-manager-container">
//       <h3>Hashtags</h3>
//       {error && <p className="error">{error}</p>}
//       <button className="button-linkstyle" onClick={toggleModal}>
//         Settings
//       </button>
//       {isModalOpen && (
//         <Modal onClose={toggleModal}>
//           <SettingsPanel
//             onGroupAdded={handleNewGroupAdded}
//             hashtagGroups={hashtagGroups}
//             hashtags={hashtags}
//             setHashtags={setHashtags}
//             selectedGroup={selectedGroup}
//             setError={setError}
//             fetchHashtagGroups={fetchData}
//           />
//         </Modal>
//       )}
//       <div className="group-selector">
//         <label htmlFor="group-select">Team:</label>
//         <select
//           id="group-select"
//           value={selectedGroup || ""}
//           onChange={handleGroupSelection}
//         >
//           {hashtagGroups.map((group) => (
//             <option key={group.customId} value={group.customId}>
//               {group.name}
//             </option>
//           ))}
//         </select>
//       </div>
//       <ul className="hashtag-list">
//         {hashtags.length > 0 ? (
//           hashtags.map(
//             (hashtag) =>
//               hashtag.tag && (
//                 <li
//                   key={hashtag.customId}
//                   className={`hashtag-item ${
//                     uiSelectedHashtags.includes(hashtag) ? "selected" : ""
//                   }`}
//                   onClick={() => handleSelectHashtag(hashtag)}
//                 >
//                   {hashtag.tag}
//                 </li>
//               )
//           )
//         ) : (
//           <p>No hashtags available</p> // Display this if there are no hashtags
//         )}
//       </ul>
//     </div>
//   );
// }

// export default HashtagManager;

// import React, { useState, useEffect } from "react";
// import * as HashtagService from "../services/HashtagService";
// import "../styles/HashtagAndItemManager.css";
// import Modal from "../../CommonElements/Modal";
// import SettingsPanel from "./HashtagSettingsPanel";

// // function HashtagManager({ setExternalSelectedHashtags }) {
// //   const [hashtags, setHashtags] = useState([]);
// //   const [hashtagGroups, setHashtagGroups] = useState([]);
// //   const [selectedGroup, setSelectedGroup] = useState(null);
// //   const [uiSelectedHashtags, setUiSelectedHashtags] = useState([]);
// //   const [isModalOpen, setIsModalOpen] = useState(false);
// //   const [error, setError] = useState(null);

// //   const fetchData = async () => {
// //     try {
// //       console.log("Fetching hashtag groups...");
// //       const fetchedGroups = await HashtagService.fetchHashtagGroups();
// //       console.log("Fetched groups:", fetchedGroups);

// //       setHashtagGroups(fetchedGroups || []);
// //       if (fetchedGroups.length > 0 && !selectedGroup) {
// //         console.log("Setting default group:", fetchedGroups[0].customId);
// //         setSelectedGroup(fetchedGroups[0].customId);
// //         fetchHashtags(fetchedGroups[0].customId); // Fetch hashtags for the first group
// //       }
// //     } catch (err) {
// //       console.error("Failed to fetch groups:", err);
// //       setError("Failed to fetch groups: " + err.message);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchData();
// //   }, []);

// //   const handleNewGroupAdded = (newGroup) => {
// //     console.log("New group added:", newGroup);
// //     setHashtagGroups((prevGroups) => [...prevGroups, newGroup]);
// //     setSelectedGroup(newGroup.customId);
// //     fetchHashtags(newGroup.customId);
// //   };
// //   const fetchHashtags = async (groupId) => {
// //     if (!groupId) {
// //       console.log("No groupId provided to fetchHashtags.");
// //       return;
// //     }
// //     try {
// //       console.log(`Fetching hashtags for group: ${groupId}`);
// //       const fetchedHashtags = await HashtagService.fetchHashtagsByGroup(
// //         groupId
// //       );
// //       console.log(`Fetched hashtags for group ${groupId}:`, fetchedHashtags);

// //       setHashtags(fetchedHashtags || []);
// //     } catch (err) {
// //       console.error("Failed to fetch hashtags:", err);
// //       setError(
// //         "Failed to fetch hashtags for the selected group: " + err.message
// //       );
// //     }
// //   };

// //   // const fetchHashtags = async (groupId) => {
// //   //   if (!groupId) return;
// //   //   try {
// //   //     console.log(`Fetching hashtags for group: ${groupId}`);
// //   //     const fetchedHashtags = await HashtagService.fetchHashtagsByGroup(
// //   //       groupId
// //   //     );
// //   //     console.log(`Fetched hashtags for group ${groupId}:`, fetchedHashtags);

// //   //     setHashtags(fetchedHashtags || []);
// //   //   } catch (err) {
// //   //     console.error("Failed to fetch hashtags:", err);
// //   //     setError(
// //   //       "Failed to fetch hashtags for the selected group: " + err.message
// //   //     );
// //   //   }
// //   // };
// //   const handleGroupSelection = (event) => {
// //     const groupId = event.target.value;
// //     console.log("Selected Group ID:", groupId); // Add this to log the selected group
// //     setSelectedGroup(groupId);
// //     fetchHashtags(groupId);
// //   };

// //   //   const handleGroupSelection = (event) => {
// //   //     const groupId = event.target.value;
// //   //     console.log("Selected group:", groupId);
// //   //     setSelectedGroup(groupId);
// //   //     fetchHashtags(groupId);
// //   //   };

// //   const handleSelectHashtag = (hashtag) => {
// //     console.log("Toggling hashtag selection:", hashtag);
// //     setUiSelectedHashtags((prev) => {
// //       const index = prev.findIndex((h) => h.customId === hashtag.customId);
// //       if (index >= 0) {
// //         console.log("Hashtag already selected, removing it.");
// //         return [...prev.slice(0, index), ...prev.slice(index + 1)];
// //       } else {
// //         console.log("Selecting new hashtag:", hashtag);
// //         return [...prev, hashtag];
// //       }
// //     });
// //   };

// //   const toggleModal = () => {
// //     console.log("Toggling settings modal");
// //     setIsModalOpen(!isModalOpen);
// //     setError(null);
// //   };

// //   return (
// //     <div className="hashtag-manager-container">
// //       <h3>Hashtags</h3>
// //       {error && <p className="error">{error}</p>}
// //       <button className="button-linkstyle" onClick={toggleModal}>
// //         Settings
// //       </button>
// //       {isModalOpen && (
// //         <Modal onClose={toggleModal}>
// //           <SettingsPanel
// //             onGroupAdded={handleNewGroupAdded}
// //             hashtagGroups={hashtagGroups}
// //             hashtags={hashtags}
// //             setHashtags={setHashtags}
// //             selectedGroup={selectedGroup}
// //             setError={setError}
// //             fetchHashtagGroups={fetchData}
// //           />
// //         </Modal>
// //       )}
// //       <div className="group-selector">
// //         <label htmlFor="group-select">Team:</label>
// //         <select
// //           id="group-select"
// //           value={selectedGroup || ""}
// //           onChange={handleGroupSelection}
// //         >
// //           {hashtagGroups.map((group) => (
// //             <option key={group.customId} value={group.customId}>
// //               {group.name}
// //             </option>
// //           ))}
// //         </select>
// //       </div>
// //       <ul className="hashtag-list">
// //         {hashtags.map((hashtag) => (
// //           <li
// //             key={hashtag.customId}
// //             className={`hashtag-item ${
// //               uiSelectedHashtags.includes(hashtag) ? "selected" : ""
// //             }`}
// //             onClick={() => handleSelectHashtag(hashtag)}
// //           >
// //             {hashtag.tag}
// //           </li>
// //         ))}
// //       </ul>
// //     </div>
// //   );
// // }

// function HashtagManager({ setExternalSelectedHashtags }) {
//   const [hashtags, setHashtags] = useState([]);
//   const [hashtagGroups, setHashtagGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState(null);
//   const [uiSelectedHashtags, setUiSelectedHashtags] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [error, setError] = useState(null);

//   const fetchData = async () => {
//     try {
//       const fetchedGroups = await HashtagService.fetchHashtagGroups();
//       setHashtagGroups(fetchedGroups || []);
//       if (fetchedGroups.length > 0 && !selectedGroup) {
//         setSelectedGroup(fetchedGroups[0].customId);
//         fetchHashtags(fetchedGroups[0].customId); // Fetch hashtags for the first group
//       }
//     } catch (err) {
//       setError("Failed to fetch groups: " + err.message);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchHashtags = async (groupId) => {
//     if (!groupId) return;
//     try {
//       const fetchedHashtags = await HashtagService.fetchHashtagsByGroup(
//         groupId
//       );
//       setHashtags(fetchedHashtags || []);
//     } catch (err) {
//       setError("Failed to fetch hashtags: " + err.message);
//     }
//   };

//   const handleGroupSelection = (event) => {
//     const groupId = event.target.value;
//     setSelectedGroup(groupId);
//     fetchHashtags(groupId); // Fetch hashtags for the selected group immediately
//   };

//   const handleSelectHashtag = (hashtag) => {
//     setUiSelectedHashtags((prev) => {
//       const index = prev.findIndex((h) => h.customId === hashtag.customId);
//       if (index >= 0) {
//         return [...prev.slice(0, index), ...prev.slice(index + 1)];
//       } else {
//         return [...prev, hashtag];
//       }
//     });
//   };

//   // Function to handle a new group being added
//   const handleNewGroupAdded = (newGroup) => {
//     console.log("New group added:", newGroup);
//     setHashtagGroups((prevGroups) => [...prevGroups, newGroup]);
//     setSelectedGroup(newGroup.customId);
//     fetchHashtags(newGroup.customId);
//   };

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//     setError(null);

//     // Fetch hashtags for the selected group when the modal is closed
//     if (!isModalOpen) {
//       fetchHashtags(selectedGroup);
//     }
//   };

//   return (
//     <div className="hashtag-manager-container">
//       <h3>Hashtags</h3>
//       {error && <p className="error">{error}</p>}
//       <button className="button-linkstyle" onClick={toggleModal}>
//         Settings
//       </button>
//       {isModalOpen && (
//         <Modal onClose={toggleModal}>
//           <SettingsPanel
//             onGroupAdded={handleNewGroupAdded}
//             hashtagGroups={hashtagGroups}
//             hashtags={hashtags}
//             setHashtags={setHashtags}
//             selectedGroup={selectedGroup}
//             setError={setError}
//             fetchHashtagGroups={fetchData}
//           />
//         </Modal>
//       )}
//       <div className="group-selector">
//         <label htmlFor="group-select">Team:</label>
//         <select
//           id="group-select"
//           value={selectedGroup || ""}
//           onChange={handleGroupSelection}
//         >
//           {hashtagGroups.map((group) => (
//             <option key={group.customId} value={group.customId}>
//               {group.name}
//             </option>
//           ))}
//         </select>
//       </div>
//       <ul className="hashtag-list">
//         {hashtags.map((hashtag) => (
//           <li
//             key={hashtag.customId}
//             className={`hashtag-item ${
//               uiSelectedHashtags.includes(hashtag) ? "selected" : ""
//             }`}
//             onClick={() => handleSelectHashtag(hashtag)}
//           >
//             {hashtag.tag}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default HashtagManager;

// export default HashtagManager;

// export default HashtagManager;

// import React, { useState, useEffect } from "react";
// import * as HashtagService from "../services/HashtagService";
// import "../styles/HashtagAndItemManager.css";
// import Modal from "../../CommonElements/Modal";
// import SettingsPanel from "./HashtagSettingsPanel";

// function HashtagManager({ setExternalSelectedHashtags }) {
//   const [hashtags, setHashtags] = useState([]);
//   const [hashtagGroups, setHashtagGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState(null);
//   const [uiSelectedHashtags, setUiSelectedHashtags] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [error, setError] = useState(null);

//   const fetchData = async () => {
//     try {
//       const fetchedGroups = await HashtagService.fetchHashtagGroups();
//       setHashtagGroups(fetchedGroups || []);
//       if (fetchedGroups.length > 0 && !selectedGroup) {
//         setSelectedGroup(fetchedGroups[0].customId); // Set default group
//         fetchHashtags(fetchedGroups[0].customId); // Fetch hashtags for the first group
//       }
//     } catch (err) {
//       setError("Failed to fetch groups: " + err.message);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const handleNewGroupAdded = (newGroup) => {
//     setHashtagGroups((prevGroups) => [...prevGroups, newGroup]);
//     setSelectedGroup(newGroup.customId);
//     fetchHashtags(newGroup.customId);
//   };

//   const fetchHashtags = async (groupId) => {
//     console.log("Fetching hashtags for group: ", groupId); // Debugging line
//     if (!groupId) return;
//     try {
//       const fetchedHashtags = await HashtagService.fetchHashtagsByGroup(
//         groupId
//       );
//       setHashtags(fetchedHashtags || []);
//     } catch (err) {
//       setError(
//         "Failed to fetch hashtags for the selected group: " + err.message
//       );
//     }
//   };

//   const handleGroupSelection = (event) => {
//     const groupId = event.target.value;
//     setSelectedGroup(groupId);
//     fetchHashtags(groupId);
//   };

//   const handleSelectHashtag = (hashtag) => {
//     setUiSelectedHashtags((prev) => {
//       const index = prev.findIndex((h) => h.customId === hashtag.customId);
//       if (index >= 0) {
//         return [...prev.slice(0, index), ...prev.slice(index + 1)];
//       } else {
//         return [...prev, hashtag];
//       }
//     });
//   };

//   const toggleModal = () => {
//     setIsModalOpen(!isModalOpen);
//     setError(null);
//   };

//   return (
//     <div className="hashtag-manager-container">
//       <h3>Hashtags</h3>
//       {error && <p className="error">{error}</p>}
//       <button className="button-linkstyle" onClick={toggleModal}>
//         Settings
//       </button>
//       {isModalOpen && (
//         <Modal onClose={toggleModal}>
//           <SettingsPanel
//             onGroupAdded={handleNewGroupAdded}
//             hashtagGroups={hashtagGroups}
//             hashtags={hashtags}
//             setHashtags={setHashtags}
//             selectedGroup={selectedGroup}
//             setError={setError}
//             fetchHashtagGroups={fetchData}
//           />
//         </Modal>
//       )}
//       <div className="group-selector">
//         <label htmlFor="group-select">Team:</label>
//         <select
//           id="group-select"
//           value={selectedGroup || ""}
//           onChange={handleGroupSelection}
//         >
//           {hashtagGroups.map((group) => (
//             <option key={group.customId} value={group.customId}>
//               {group.name}
//             </option>
//           ))}
//         </select>
//       </div>
//       <ul className="hashtag-list">
//         {hashtags.map((hashtag) => (
//           <li
//             key={hashtag.customId}
//             className={`hashtag-item ${
//               uiSelectedHashtags.includes(hashtag) ? "selected" : ""
//             }`}
//             onClick={() => handleSelectHashtag(hashtag)}
//           >
//             {hashtag.tag}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default HashtagManager;

// // import React, { useState, useEffect } from "react";
// // import * as HashtagService from "../services/HashtagService";
// // import "../styles/HashtagAndItemManager.css";
// // import Modal from "../../CommonElements/Modal"; // Ensure correct path
// // import SettingsPanel from "./HashtagSettingsPanel"; // Ensure SettingsPanel is correctly imported

// // function HashtagManager({ setExternalSelectedHashtags }) {
// //   const [hashtags, setHashtags] = useState([]);
// //   const [hashtagGroups, setHashtagGroups] = useState([]);
// //   const [selectedGroup, setSelectedGroup] = useState(null);
// //   const [uiSelectedHashtags, setUiSelectedHashtags] = useState([]); // Local state for UI selection
// //   const [isModalOpen, setIsModalOpen] = useState(false);
// //   const [error, setError] = useState(null);

// //   const fetchData = async () => {
// //     try {
// //       const fetchedGroups = await HashtagService.fetchHashtagGroups();
// //       setHashtagGroups(fetchedGroups || []);
// //       if (fetchedGroups.length > 0 && !selectedGroup) {
// //         setSelectedGroup(fetchedGroups[0].customId);
// //         fetchHashtags(fetchedGroups[0].customId);
// //       }
// //     } catch (err) {
// //       setError("Failed to fetch data: " + err.message);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchData();
// //   }, []);

// //   // Function to handle new group added
// //   const handleNewGroupAdded = (newGroup) => {
// //     setHashtagGroups((prevGroups) => [...prevGroups, newGroup]);
// //     setSelectedGroup(newGroup.customId);
// //     fetchHashtags(newGroup.customId);
// //   };

// //   const fetchHashtags = async (groupId) => {
// //     if (!groupId) return;
// //     try {
// //       const fetchedHashtags = await HashtagService.fetchHashtagsByGroup(
// //         groupId
// //       );
// //       setHashtags(fetchedHashtags || []);
// //     } catch (err) {
// //       setError(
// //         "Failed to fetch hashtags for the selected group: " + err.message
// //       );
// //     }
// //   };

// //   const handleGroupSelection = (event) => {
// //     const groupId = event.target.value;
// //     setSelectedGroup(groupId);
// //     fetchHashtags(groupId);
// //   };

// //   const handleSelectHashtag = (hashtag) => {
// //     setUiSelectedHashtags((prev) => {
// //       const index = prev.findIndex((h) => h.customId === hashtag.customId);
// //       if (index >= 0) {
// //         return [...prev.slice(0, index), ...prev.slice(index + 1)];
// //       } else {
// //         return [...prev, hashtag];
// //       }
// //     });
// //   };

// //   const toggleModal = () => {
// //     setIsModalOpen(!isModalOpen);
// //     setError(null);
// //   };

// //   return (
// //     <div className="hashtag-manager-container">
// //       <h3>Hashtags</h3>
// //       {error && <p className="error">{error}</p>}
// //       <button className="button-linkstyle" onClick={toggleModal}>
// //         Settings
// //       </button>
// //       {isModalOpen && (
// //         <Modal onClose={toggleModal}>
// //           <SettingsPanel
// //             onGroupAdded={handleNewGroupAdded}
// //             hashtagGroups={hashtagGroups}
// //             hashtags={hashtags}
// //             setHashtags={setHashtags}
// //             selectedGroup={selectedGroup}
// //             setError={setError}
// //             fetchHashtagGroups={fetchData} // Assuming this function fetches the groups
// //           />
// //         </Modal>
// //       )}
// //       <div className="group-selector">
// //         <label htmlFor="group-select">Team:</label>
// //         <select
// //           id="group-select"
// //           value={selectedGroup || ""}
// //           onChange={handleGroupSelection}
// //         >
// //           {hashtagGroups.map((group) => (
// //             <option key={group.customId} value={group.customId}>
// //               {group.name}
// //             </option>
// //           ))}
// //         </select>
// //       </div>
// //       <ul className="hashtag-list">
// //         {hashtags.map((hashtag) => (
// //           <li
// //             key={hashtag.customId}
// //             className={`hashtag-item ${
// //               uiSelectedHashtags.includes(hashtag) ? "selected" : ""
// //             }`}
// //             onClick={() => handleSelectHashtag(hashtag)}
// //           >
// //             {hashtag.tag}
// //           </li>
// //         ))}
// //       </ul>
// //     </div>
// //   );
// // }

// // export default HashtagManager;
