import React, { useState, useEffect } from "react";
import "./ActionPanel.css";
function PriorityComponent({ itemId, initialPriority, onPriorityUpdated }) {
  const [priority, setPriority] = useState(initialPriority);

  // Update the local state when the initialPriority prop changes
  useEffect(() => {
    setPriority(initialPriority);
  }, [initialPriority]);

  const handleChange = async (event) => {
    const newPriority = event.target.value;
    const username = localStorage.getItem("username");

    // Ensure 'username' is defined before making the request
    if (!username) {
      console.error("Username not found in local storage.");
      return;
    }

    console.log(
      `Updating priority for item ${itemId} to '${newPriority}' by user '${username}'`
    );

    try {
      const response = await fetch(`/api/items/${itemId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ priority: newPriority, user: username }),
      });

      if (response.ok) {
        const updatedItem = await response.json();
        setPriority(newPriority); // Update local state
        onPriorityUpdated(updatedItem); // Notify parent component about the update
      } else {
        console.error("Failed to update priority");
      }
    } catch (error) {
      console.error("Error updating priority:", error);
    }
  };

  return (
    <div>
      <label htmlFor="priority-select">Priority:</label>
      <select
        id="priority-select"
        value={priority}
        onChange={handleChange}
        className="priority-box"
      >
        <option value="P0 - Blocker">P0 - Blocker</option>
        <option value="P1 - Important">P1 - Important</option>
        <option value="P2 - Medium">P2 - Medium</option>
        <option value="P3 - Low Priority">P3 - Low Priority</option>
      </select>
    </div>
  );
}

export default PriorityComponent;
