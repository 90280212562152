import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const ResetPassword = () => {
  const { token } = useParams(); // Get token from URL params
  const navigate = useNavigate(); // To navigate to another route
  const [isVerified, setIsVerified] = useState(false); // Track if the token is verified
  const [verificationError, setVerificationError] = useState("");
  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState(""); // State for new password
  const [passwordSuccess, setPasswordSuccess] = useState(""); // State for success message
  const [passwordError, setPasswordError] = useState(""); // State for error message

  useEffect(() => {
    const verifyToken = async () => {
      try {
        // Verify token with backend API
        const response = await axios.post(`/api/verifytoken/${token}`);
        if (response.data.verified) {
          setIsVerified(true); // Token is verified
        }
      } catch (error) {
        setVerificationError("Invalid or expired reset link.");
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [token]);

  // Function to handle password reset
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setPasswordError(""); // Clear previous error
    setPasswordSuccess(""); // Clear previous success

    try {
      const response = await axios.post(`/api/resetpassword/${token}`, {
        password: newPassword,
      });

      if (response.data.message === "Password has been reset successfully") {
        setPasswordSuccess("Password has been reset successfully.");

        // Redirect to login page after 3 seconds
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setPasswordError("Failed to reset password.");
      }
    } catch (error) {
      setPasswordError("Error resetting password. Please try again.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: "400px", padding: "20px", textAlign: "center" }}>
        {loading ? (
          <p>Verifying reset link...</p>
        ) : isVerified ? (
          <>
            <Alert variant="success">
              Verified! You can now reset your password.
            </Alert>

            {/* Reset Password Form */}
            <Form onSubmit={handleResetPassword}>
              <Form.Group controlId="formNewPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button type="submit" className="mt-3">
                Reset Password
              </Button>
            </Form>

            {passwordSuccess && (
              <Alert variant="success" className="mt-3">
                {passwordSuccess}
              </Alert>
            )}
            {passwordError && (
              <Alert variant="danger" className="mt-3">
                {passwordError}
              </Alert>
            )}
          </>
        ) : (
          <Alert variant="danger">{verificationError}</Alert>
        )}
      </Card>
    </div>
  );
};

export default ResetPassword;
