export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3000"; // Ensure your environment variable is set or use a fallback

/**
 * A utility function to handle API requests with added error handling and authorization.
 * @param {string} url The endpoint to hit relative to the base URL.
 * @param {string} method The HTTP method to use (default is 'GET').
 * @param {Object|null} data The payload for POST/PUT requests.
 * @param {Object} headers Additional headers for the request.
 * @returns {Promise<any>} The JSON response from the API.
 * @throws {Error} If the request fails or the API returns a non-2xx status.
 */

export const apiRequest = async (
  url,
  method = "GET",
  data = null,
  headers = {}
) => {
  const fullUrl = `${apiUrl}${url}`;
  console.log(`Making API Request to: ${fullUrl}`);
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 seconds timeout

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      ...headers,
    },
    body: data ? JSON.stringify(data) : null,
    signal: controller.signal,
    cache: "no-store", // Add this line to bypass cache
  };

  try {
    const response = await fetch(fullUrl, options);
    clearTimeout(timeoutId); // Clear the timeout as soon as the fetch completes or fails
    const contentType = response.headers.get("Content-Type");

    if (contentType && contentType.includes("application/json")) {
      const responseData = await response.json();
      if (!response.ok) {
        console.error(
          `API call failed with status ${response.status}`,
          responseData
        );
        throw new Error(
          responseData.message ||
            `Server responded with status ${response.status}`
        );
      }
      return responseData;
    } else {
      const responseText = await response.text();
      if (!response.ok) {
        console.error(
          `API call failed with status ${response.status}`,
          responseText
        );
        throw new Error(
          `Non-JSON response from server. Status: ${response.status}. Response: ${responseText}`
        );
      }
      console.warn(
        `Expected JSON but received content type ${contentType}. Raw response:`,
        responseText
      );
      return responseText;
    }
  } catch (error) {
    if (error.name === "AbortError") {
      console.error(`Request to ${fullUrl} timed out.`);
      throw new Error("Request timed out. Please try again.");
    }
    console.error(`Request to ${fullUrl} failed: ${error.message || error}`);
    throw error;
  }
};

// export const apiRequest = async (
//   url,
//   method = "GET",
//   data = null,
//   headers = {}
// ) => {
//   const fullUrl = `${apiUrl}${url}`;
//   console.log(`Making API Request to: ${fullUrl}`);
//   const controller = new AbortController();
//   const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 seconds timeout

//   const options = {
//     method,
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//       ...headers,
//     },
//     body: data ? JSON.stringify(data) : null,
//     signal: controller.signal,
//   };

//   try {
//     const response = await fetch(fullUrl, options);
//     clearTimeout(timeoutId); // Clear the timeout as soon as the fetch completes or fails
//     const contentType = response.headers.get("Content-Type");

//     // Handle JSON response
//     if (contentType && contentType.includes("application/json")) {
//       const responseData = await response.json();
//       if (!response.ok) {
//         console.error(
//           `API call failed with status ${response.status}`,
//           responseData
//         );
//         throw new Error(
//           responseData.message ||
//             `Server responded with status ${response.status}`
//         );
//       }
//       return responseData;

//       // Handle non-JSON responses
//     } else {
//       const responseText = await response.text();
//       if (!response.ok) {
//         console.error(
//           `API call failed with status ${response.status}`,
//           responseText
//         );
//         throw new Error(
//           `Non-JSON response from server. Status: ${response.status}. Response: ${responseText}`
//         );
//       }
//       console.warn(
//         `Expected JSON but received content type ${contentType}. Raw response:`,
//         responseText
//       );
//       return responseText;
//     }
//   } catch (error) {
//     // Handle fetch/timeout errors
//     if (error.name === "AbortError") {
//       console.error(`Request to ${fullUrl} timed out.`);
//       throw new Error("Request timed out. Please try again.");
//     }
//     console.error(`Request to ${fullUrl} failed: ${error.message || error}`);
//     throw error;
//   }
// };
// export const apiRequest = async (url, method = 'GET', data = null, headers = {}) => {
//   const fullUrl = `${apiUrl}${url}`;
//   console.log(`Making API Request to: ${fullUrl}`);
//   const controller = new AbortController();
//   const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 seconds timeout

//   const options = {
//       method,
//       headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${localStorage.getItem('token')}`,
//           ...headers,
//       },
//       body: data ? JSON.stringify(data) : null,
//       signal: controller.signal
//   };

//   try {
//       const response = await fetch(fullUrl, options);
//       clearTimeout(timeoutId); // Clear the timeout as soon as the fetch completes or fails
//       const contentType = response.headers.get('Content-Type');
//       if (contentType && contentType.includes('application/json')) {
//           const responseData = await response.json();
//           if (!response.ok) {
//               console.error(`API call failed with status ${response.status}`, responseData);
//               throw new Error(responseData.message || `Server responded with status ${response.status}`);
//           }
//           return responseData;
//       } else {
//           const errorMessage = `Expected JSON but received content type ${contentType}`;
//           console.error(errorMessage);
//           throw new Error(errorMessage);
//       }
//   } catch (error) {
//       console.error(`Request to ${fullUrl} failed: ${error}`);
//       throw error;
//   }
// };
