import React, { useState } from "react";
import * as HashtagService from "../services/HashtagService";
import "../styles/SettingPanel.css";

function HashtagSettingsPanel({
  hashtagGroups,
  hashtags,
  setHashtags,
  fetchHashtagGroups,
  onGroupAdded,
}) {
  const [formData, setFormData] = useState({
    newHashtag: "",
    newDescription: "",
    newGroupName: "",
    editHashtagId: "",
    editHashtag: "",
    editDescription: "",
    editGroupName: "",
    multipleGroupNames: [], // For multiple group selection
  });
  const [localError, setLocalError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Add New Group
  const handleAddGroup = async () => {
    if (!formData.newGroupName) {
      setLocalError("Group name is required");
      return;
    }
    try {
      const newGroup = await HashtagService.createHashtagGroup({
        name: formData.newGroupName,
      });
      fetchHashtagGroups();
      onGroupAdded(newGroup);
      setFormData((prev) => ({ ...prev, newGroupName: "" }));
      setSuccessMessage("Group added successfully");
      setLocalError(null);
    } catch (err) {
      setLocalError("Failed to add new group: " + err.message);
    }
  };

  // Select Hashtag for Editing
  const handleSelectHashtag = (event) => {
    const selectedId = event.target.value;
    const hashtag = hashtags.find((h) => h.customId === selectedId);
    if (hashtag) {
      setFormData({
        editHashtagId: selectedId,
        editHashtag: hashtag.tag,
        editDescription: hashtag.description,
        editGroupName: hashtag.group, // Single group if applicable
        multipleGroupNames: hashtag.groups || [], // Populate selected groups
      });
    }
  };

  // Handling Group Selection Change for Assigning to Multiple Groups
  const handleGroupSelectionChange = (event) => {
    const selectedGroups = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setFormData((prevData) => ({
      ...prevData,
      multipleGroupNames: selectedGroups,
    }));
  };

  // Save or Update Hashtag & Assign to Groups
  const handleSaveAndAssign = async () => {
    // Validate input
    if (!formData.editHashtag && !formData.newHashtag) {
      setLocalError("Please enter a hashtag name.");
      return;
    }
    if (formData.multipleGroupNames.length === 0) {
      setLocalError("Please select at least one group.");
      return;
    }

    try {
      let savedHashtag;

      // If editing an existing hashtag
      if (formData.editHashtagId) {
        savedHashtag = await HashtagService.updateHashtag(formData.editHashtagId, {
          tag: formData.editHashtag,
          description: formData.editDescription,
          group: formData.editGroupName, // Optional: for single group if applicable
        });
        setSuccessMessage("Hashtag updated successfully");
      } else {
        // Add new hashtag
        savedHashtag = await HashtagService.createHashtag({
          tag: formData.newHashtag,
          description: formData.newDescription,
          group: formData.editGroupName, // Optional: for single group if applicable
        });
        setSuccessMessage("Hashtag added successfully");
      }

      // After creating or updating, assign to multiple groups
      if (formData.multipleGroupNames.length > 0) {
        const response = await HashtagService.assignHashtagToGroups({
          hashtagId: savedHashtag.customId,
          groups: formData.multipleGroupNames,
        });

        // Update hashtag in the state after assigning to groups
        setHashtags((prev) =>
          prev.map((h) =>
            h.customId === savedHashtag.customId ? response : h
          )
        );
        setSuccessMessage("Hashtag successfully saved and assigned to selected groups.");
      }

      // Clear form fields after successful save and assignment
      setFormData({
        newHashtag: "",
        newDescription: "",
        newGroupName: "",
        editHashtagId: "",
        editHashtag: "",
        editDescription: "",
        editGroupName: "",
        multipleGroupNames: [],
      });
      setLocalError(null);
    } catch (err) {
      setLocalError("Failed to save and assign hashtag: " + err.message);
    }
  };

  return (
    <div className="settings-panel">
      {localError && <p className="error-message">{localError}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}

      {/* Add New Group */}
      <div className="form-section">
        <h4>Add New Group</h4>
        <label htmlFor="newGroupName">Group Name</label>
        <input
          id="newGroupName"
          name="newGroupName"
          value={formData.newGroupName}
          onChange={handleInputChange}
          placeholder="Enter group name"
        />
        <button onClick={handleAddGroup}>Add Group</button>
      </div>

      {/* Add New Hashtag */}
      <div className="form-section">
        <h4>Add New Hashtag</h4>
        <label htmlFor="newHashtag">Hashtag</label>
        <input
          id="newHashtag"
          name="newHashtag"
          value={formData.newHashtag}
          onChange={handleInputChange}
          placeholder="Enter hashtag"
        />
        <label htmlFor="newDescription">Description</label>
        <input
          id="newDescription"
          name="newDescription"
          value={formData.newDescription}
          onChange={handleInputChange}
          placeholder="Enter description"
        />
        <label htmlFor="selectGroup">Select a Group</label>
        <select
          id="selectGroup"
          name="editGroupName"
          value={formData.editGroupName}
          onChange={handleInputChange}
        >
          <option value="">Select a Group</option>
          {hashtagGroups.map((group) => (
            <option key={group.customId} value={group.customId}>
              {group.name}
            </option>
          ))}
        </select>
      </div>

      {/* Update Hashtag & Assign to Groups */}
      <div className="form-section">
        <h4>Update Hashtag & Assign to Groups</h4>
        <label htmlFor="selectHashtag">Select Hashtag</label>
        <select
          id="selectHashtag"
          value={formData.editHashtagId}
          onChange={handleSelectHashtag}
        >
          <option value="">Select a Hashtag</option>
          {hashtags.map((hashtag) => (
            <option key={hashtag.customId} value={hashtag.customId}>
              {hashtag.tag}
            </option>
          ))}
        </select>

        {formData.editHashtagId && (
          <>
            <label htmlFor="editHashtag">Edit Hashtag</label>
            <input
              id="editHashtag"
              name="editHashtag"
              value={formData.editHashtag}
              onChange={handleInputChange}
              placeholder="Enter updated hashtag"
            />
            <label htmlFor="editDescription">Edit Description</label>
            <input
              id="editDescription"
              name="editDescription"
              value={formData.editDescription}
              onChange={handleInputChange}
              placeholder="Enter updated description"
            />

            <label htmlFor="multipleGroups">Assign to Multiple Groups</label>
            <select
              id="multipleGroups"
              className="select-multiple"
              name="multipleGroupNames"
              value={formData.multipleGroupNames} // Pre-select current groups
              onChange={handleGroupSelectionChange}
              multiple
            >
              {hashtagGroups.map((group) => (
                <option key={group.customId} value={group.customId}>
                  {group.name}
                </option>
              ))}
            </select>
            <p className="multi-select-instruction">
              Hold Ctrl (Windows) or Cmd (Mac) to select multiple groups.
            </p>
            <button onClick={handleSaveAndAssign}>Save Hashtag & Assign to Groups</button>
          </>
        )}
      </div>
    </div>
  );
}

export default HashtagSettingsPanel;
