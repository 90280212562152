export const copyToClipboard = (text) => {
    return new Promise((resolve, reject) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(text).then(resolve).catch(reject);
        } else {
            try {
                const textArea = document.createElement("textarea");
                // Style the textarea to be invisible and out of the way
                textArea.style.position = 'fixed';
                textArea.style.left = '-9999px';
                textArea.style.top = '0';
                textArea.value = text;
                
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                const successful = document.execCommand('copy');
                document.body.removeChild(textArea);

                if (successful) {
                    resolve();
                } else {
                    throw new Error('Failed to copy using execCommand');
                }
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
                reject(err);
            }
        }
    });
};
