import React, { useState } from "react";
import { Form, Button, Container, Alert, Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(`/api/login`, {
        email: email.trim(),
        password: password.trim(),
        username: username.trim(),
      });

      if (response.data.token) {
        localStorage.setItem("userToken", response.data.token);
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("isUserLoggedIn", true);
        localStorage.setItem("userRole", response.data.role);
        console.log("Stored token:", localStorage.getItem("userToken"));
        window.location.href = "/main";
      } else {
        throw new Error("Token not found in response");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Login failed. Please try again.");
    }
  };

  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };
  const cardStyle = {
    width: "400px",
    borderRadius: "15px",

    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };
  const inputStyle = {
    height: "45px",
    fontSize: "1em",
  };
  const buttonStyle = {
    width: "100%",
    backgroundColor: "#007bff",
    borderColor: "#007bff",
  };

  return (
    <div style={wrapperStyle}>
      <Card style={cardStyle}>
        <Card.Body>
          <h3 className="text-center mb-4">Login</h3>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Button type="submit" className="mt-4" style={buttonStyle}>
              Login
            </Button>
            <div className="text-center mt-3">
              <Link to="/signup">Don't have an account? Sign Up</Link>
            </div>
            <div className="text-center mt-2">
              <Link to="/forgotpassword">ForgotPassword</Link>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
