import React from 'react';

const HashtagGroupsPage = () => {
  return (
    <div>
      <h1>Hashtag Groups Page</h1>
      <p>This is where you can manage your hashtag groups.</p>
    </div>
  );
};

export default HashtagGroupsPage;
