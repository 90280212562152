import "./Modal.css";

function Modal({ children, onClose }) {
    return (
        <div className="modal-backdrop" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>Close</button>
                {children}
            </div>
        </div>
    );
}

export default Modal;  // Add this line to export Modal as a default export
