// ItemServices.js
const API_URL = process.env.REACT_APP_API_URL;

const fetchItems = async (selectedHashtags) => {
  const customIds = selectedHashtags.map((ht) => ht.customId).join(",");
  const response = await fetch(`${API_URL}/items?hashtags=${customIds}`);
  if (!response.ok) {
    throw new Error("Error fetching items");
  }
  return await response.json();
};

// ItemServices.js
export const fetchItem = async (itemId) => {
  const response = await fetch(`${API_URL}/items/${itemId}`);
  if (!response.ok) {
    throw new Error(
      `Error fetching item with ID ${itemId}: ${response.statusText}`
    );
  }
  return await response.json();
};

// ...rest of your ItemServices.js code

// const fetchItemsByHashtags = async (hashtags) => {
//   try {
//     const response = await fetch(
//       `${API_URL}/tasks/by-hashtags?hashtags=${hashtags.join(",")}`
//     );
//     if (!response.ok) {
//       throw new Error("Could not fetch tasks, server responded with an error.");
//     }
//     const data = await response.json();
//     console.log("Tasks fetched successfully:", data);
//     return data;
//   } catch (error) {
//     console.error("Error fetching tasks by hashtags:", error);
//     throw error;
//   }
// };
export const fetchItemsByHashtags = async (hashtags) => {
  console.log("Hashtags received:", hashtags); // Check what hashtags are being passed

  if (!hashtags || !hashtags.length) {
    console.error("No hashtags provided for API call");
    return []; // Return an empty array or handle the error as appropriate
  }

  const joinedHashtagIds = hashtags.join(",");
  const apiUrl = `${API_URL}/tasks/by-hashtags?hashtags=${joinedHashtagIds}`;
  console.log("Requesting API at URL:", apiUrl); // Log the exact URL being requested

  const response = await fetch(apiUrl);

  if (!response.ok) {
    throw new Error(`Error fetching tasks: ${response.statusText}`);
  }

  return await response.json();
};

//hard code data below

// export const fetchItemsByHashtags = async (hashtags) => {
//   if (!Array.isArray(hashtags)) {
//     throw new Error(`Expected an array of hashtags, got ${typeof hashtags}`);
//   }

//   // Hardcoded for the purpose of testing - replace with actual IDs
//   const hardcodedHashtagIds = ["00008", "00007", "00005"];
//   console.log("Hardcoded hashtag IDs:", hardcodedHashtagIds); // Log the hardcoded IDs

//   // Encode the parameters to ensure the URL is correct
//   const joinedHashtagIds = hardcodedHashtagIds
//     .map(encodeURIComponent)
//     .join(",");
//   const response = await fetch(
//     `${API_URL}/tasks/by-hashtags?hashtags=${joinedHashtagIds}`
//   );

//   const data = await response.json();
//   console.log("Response data:", data); // Log the response data

//   if (!response.ok) {
//     throw new Error(`Error fetching tasks: ${response.statusText}`);
//   }

//   return data;
// };

export default {
  fetchItems,
  fetchItemsByHashtags,
  // other item-related functions
};
