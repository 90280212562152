import React from "react";
import HashtagComponent from "./HashtagComponent";
import PriorityComponent from "./PriorityComponent";
import StatusComponent from "./StatusComponent";
import ETAComponent from "./ETAComponent";
import AssigneeComponent from "./AssigneeComponent";
// import "./ActionPanel.css";
function ActionPanel({ selectedItem, onItemUpdated }) {
  const username = localStorage.getItem("username"); // Fetch username from local storage

  if (!selectedItem) {
    return (
      <div className="actionPanel">
        <p>Please select a task to view its action panel.</p>
      </div>
    );
  }

  const onSaveHashtags = (updatedHashtags) => {
    // Ensure that onItemUpdated is called correctly
    if (typeof onItemUpdated === "function") {
      const updatedItem = {
        ...selectedItem,
        hashtags: updatedHashtags.map((hashtag) => hashtag.value),
      };
      onItemUpdated(updatedItem);
    } else {
      console.error("onItemUpdated is not a function");
    }
  };

  const handleSaveHashtags = (updatedHashtags) => {
    console.log("Saving hashtags:", updatedHashtags);
    if (typeof onItemUpdated === "function") {
      const updatedItem = {
        ...selectedItem,
        hashtags: updatedHashtags.map((hashtag) => hashtag.value),
      };
      console.log("Updated item with new hashtags:", updatedItem);
      onItemUpdated(updatedItem);
    } else {
      console.error("onItemUpdated is not a function");
    }
  };

  const handleSaveAssignee = (itemId, newAssigneeUsername) => {
    if (typeof onItemUpdated === "function") {
      const updatedItem = { ...selectedItem, assignee: newAssigneeUsername };
      onItemUpdated(updatedItem);
    } else {
      console.error("onItemUpdated is not a function", onItemUpdated);
      // Handle the case where onItemUpdated is not a function
    }
  };

  // const handleSaveHashtags = (updatedHashtags) => {
  //   // Define your logic for saving hashtags here
  //   console.log("Saving hashtags:", updatedHashtags);
  // };

  return (
    <div className="ActionPanels">
      <h3>Actions</h3>

      <PriorityComponent
        itemId={selectedItem.itemId}
        initialPriority={selectedItem.priority}
        onPriorityUpdated={onItemUpdated}
        username={username}
      />

      <StatusComponent
        itemId={selectedItem.itemId}
        initialStatus={selectedItem.status}
        onStatusUpdated={onItemUpdated}
        username={username}
      />

      <HashtagComponent
        selectedItem={selectedItem}
        onSaveHashtags={handleSaveHashtags}
      />

      <AssigneeComponent
        itemId={selectedItem.itemId}
        assignee={selectedItem.assignee}
        handleSaveAssignee={handleSaveAssignee}
      />

      <ETAComponent itemId={selectedItem.itemId} username={username} />
    </div>
  );
}

export default ActionPanel;
