import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart } from 'react-google-charts';
import './GanttChartView.css';

interface Task {
  itemId: string;
  name: string;
  description: string;
  creation_date: string;
  start_date?: string | null;
  end_date?: string | null;
  final_fix_date?: string | null;
  priority: string;
  status: string;
  assignee: string;
  hashtags: string[];
}

interface FilterValues {
  statuses: string[];
  assignees: string[];
  priorities: string[];
  hashtags: { value: string; label: string }[];
}

const GanttChartView: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    statuses: [],
    assignees: [],
    priorities: [],
    hashtags: [],
  });
  const [filters, setFilters] = useState({
    status: '',
    assignee: '',
    hashtags: '',
    priority: '',
    creationDateFrom: '',
    creationDateTo: '',
    startDateFrom: '',
    startDateTo: '',
    endDateFrom: '',
    endDateTo: '',
    fixDateFrom: '',
    fixDateTo: ''
  });

  


  useEffect(() => {
    const fetchFilterValues = async () => {
      try {
        const response = await axios.get('/api/unique-values');
        const { hashtags, statuses, assignees, priorities } = response.data;
        setFilterValues({
          statuses,
          assignees,
          priorities,
          hashtags: hashtags.map((tag: string) => ({
            value: tag,
            label: tag,
          })),
        });
      } catch (error) {
        console.error('Failed to fetch filter values', error);
      }
    };
    fetchFilterValues();
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('/api/items/filtered', {
          params: filters,
        });
         console.log('Fetched tasks:', response.data);
        setTasks(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        setError('Failed to fetch tasks');
        console.log('Failed to fetch tasks', error);
      }
    };
    fetchTasks();
  }, [filters]);










  const parseDate = (dateStr: string | undefined | null): Date | null => {
  if (dateStr && !isNaN(Date.parse(dateStr))) {
    return new Date(dateStr);
  }
  return null;
};



  const calculateDuration = (start: Date | null, end: Date | null): number => {
    if (start && end) {
      return end.getTime() - start.getTime();
    }
    return 0;
  };

  const chartData = [
    [
      { type: 'string', label: 'Task ID' },
      { type: 'string', label: 'Task Name' },
      { type: 'date', label: 'Start Date' },
      { type: 'date', label: 'End Date' },
      { type: 'number', label: 'Duration' },
      { type: 'number', label: 'Percent Complete' },
      { type: 'string', label: 'Dependencies' },
    ],
    
     ...tasks.map(task => {
    const startDate = parseDate(task.start_date) || parseDate(task.creation_date);
    const endDate = parseDate(task.end_date) || parseDate(task.final_fix_date) || startDate;
    const duration = calculateDuration(startDate, endDate);
    return [
      task.itemId,
      task.name,
      startDate || new Date(), // Default to current date if startDate is null
      endDate || new Date(), // Default to current date if endDate is null
      duration > 0 ? duration : 0,
      100,
      null,
    ];
  }),
];
  

  return (
    <div className="gantt-container">
      <h1>Task Timeline</h1>
      <div className="filters">
        <label>
          Status:
          <select
            name="status"
            value={filters.status}
            onChange={e => setFilters({ ...filters, status: e.target.value })}
          >
            <option value="">All</option>
            {filterValues.statuses.map(status => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </label>
        <label>
          Assignee:
          <select
            name="assignee"
            value={filters.assignee}
            onChange={e => setFilters({ ...filters, assignee: e.target.value })}
          >
            <option value="">All</option>
            {filterValues.assignees.map(assignee => (
              <option key={assignee} value={assignee}>
                {assignee}
              </option>
            ))}
          </select>
        </label>
        <label>
          Hashtags:
          <select
            name="hashtags"
            value={filters.hashtags}
            onChange={e => setFilters({ ...filters, hashtags: e.target.value })}
          >
            <option value="">All</option>
            {filterValues.hashtags.map(hashtag => (
              <option key={hashtag.value} value={hashtag.value}>
                {hashtag.label}
              </option>
            ))}
          </select>
        </label>
        <label>
          Priority:
          <select
            name="priority"
            value={filters.priority}
            onChange={e => setFilters({ ...filters, priority: e.target.value })}
          >
            <option value="">All</option>
            {filterValues.priorities.map(priority => (
              <option key={priority} value={priority}>
                {priority}
              </option>
            ))}
          </select>
        </label>
       <label className="creation-date">
  Creation Date From:
  <input type="date" onChange={(e) => setFilters({ ...filters, creationDateFrom: e.target.value })} />
</label>

<label className="creation-date">
  Creation Date To:
  <input type="date" onChange={(e) => setFilters({ ...filters, creationDateTo: e.target.value })} />
</label>

<label className="start-date">
  Start Date From:
  <input type="date" onChange={(e) => setFilters({ ...filters, startDateFrom: e.target.value })} />
</label>

<label className="start-date">
  Start Date To:
  <input type="date" onChange={(e) => setFilters({ ...filters, startDateTo: e.target.value })} />
</label>

<label className="end-date">
  End Date From:
  <input type="date" onChange={(e) => setFilters({ ...filters, endDateFrom: e.target.value })} />
</label>

<label className="end-date">
  End Date To:
  <input type="date" onChange={(e) => setFilters({ ...filters, endDateTo: e.target.value })} />
</label>

<label className="fix-date">
  Fix Date From:
  <input type="date" onChange={(e) => setFilters({ ...filters, fixDateFrom: e.target.value })} />
</label>

<label className="fix-date">
  Fix Date To:
  <input type="date" onChange={(e) => setFilters({ ...filters, fixDateTo: e.target.value })} />
</label>

      </div>
      <div className="gantt-chart-wrapper" style={{ height: '70vh', overflowY: 'auto' }}>
        <Chart
          chartType="Gantt"
          width="100%"
          height="100%"
          data={chartData}
          options={{
            gantt: {
              trackHeight: 30,
              criticalPathEnabled: false,
              defaultStartDateMillis: new Date().getTime() - 6 * 30 * 24 * 60 * 60 * 1000,
              innerGridTrack: { fill: '#e6e6e6' }, 
            },
          }}
          loader={<div>Loading Chart...</div>}
        />
      </div>
      <div className="tasks-without-dates">
        <h2>Tasks Without Start and End Dates</h2>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Status</th>
              <th>Priority</th>
              <th>Assignee</th>
            </tr>
          </thead>
          <tbody>
            {tasks
              .filter(task => !task.start_date && !task.end_date && task.status !== 'completed')
              .map(task => (
                <tr key={task.itemId}>
                  <td>{task.itemId}</td>
                  <td>{task.name}</td>
                  <td>{task.description || 'No description available'}</td>
                  <td>{task.status}</td>
                  <td>{task.priority}</td>
                  <td>{task.assignee}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GanttChartView;
