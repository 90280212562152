import React, { useState, useEffect } from "react";
import "./ActionPanel.css";
function StatusComponent({
  itemId,
  initialStatus,
  onStatusUpdated = () => {},
}) {
  const [status, setStatus] = useState(initialStatus);

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  const handleChange = async (event) => {
    const newStatus = event.target.value;
    const username = localStorage.getItem("username"); // Fetch the username from local storage

    if (!username) {
      console.error("Username not found in local storage.");
      return;
    }

    try {
      const response = await fetch(`/api/items/${itemId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          status: newStatus,
          user: username, // Send username in the request body
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update status: " + response.statusText);
      }

      const updatedItem = await response.json();
      setStatus(newStatus); // Update local state
      onStatusUpdated(updatedItem); // Safely call the function provided via props
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <div>
      <label htmlFor="status-select">Status:</label>
      <select
        id="status-select"
        value={status}
        onChange={handleChange}
        className="priority-box"
      >
        <option value="New">New</option>
        <option value="In progress (Assigned)">In progress (Assigned)</option>
        <option value="Fixed (Untested)">Fixed (Untested)</option>
        <option value="Fixed (Tested OK)">Fixed (Tested OK)</option>
        <option value="Obsolete (Not relevant)">Obsolete (Not relevant)</option>
      </select>
    </div>
  );
}

export default StatusComponent;
