import React, { useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      const response = await axios.post("/api/forgotpassword", { email });

      // Check if the response contains an error message or success message
      if (response.data.message === "Password reset email sent successfully") {
        setSuccess("Password reset link sent to your email.");
      } else {
        setError("Failed to send password reset link. Please try again.");
      }
    } catch (error) {
      console.log("Error sending reset link:", error);

      // Error handling based on server response
      if (error.response) {
        // The server responded with a status other than 2xx
        if (error.response.status === 404) {
          setError("No account found with this email.");
        } else if (error.response.status === 500) {
          setError("Server error. Please try again later.");
        } else {
          setError("Failed to send password reset link. Please try again.");
        }
      } else {
        // Handle network errors
        setError("Network error. Please check your connection.");
      }
    }
  };

  const wrapperStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };

  const cardStyle = {
    width: "400px",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const inputStyle = {
    height: "45px",
    fontSize: "1em",
  };

  const buttonStyle = {
    width: "100%",
    backgroundColor: "#007bff",
    borderColor: "#007bff",
  };

  return (
    <div style={wrapperStyle}>
      <Card style={cardStyle}>
        <Card.Body>
          <h3 className="text-center mb-4">Forgot Password</h3>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
                style={inputStyle}
              />
            </Form.Group>
            <Button type="submit" className="mt-4" style={buttonStyle}>
              Send Reset Link
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ForgotPassword;
