import React from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../redux/actions"; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // useNavigate for navigation

  const buttonstyle = {
    marginLeft: "980px",
  };

  const handleLogout = () => {
    // Perform any pre-logout actions, like API calls if necessary

    // For simplicity, if you're just removing the token and dispatching:
    localStorage.removeItem("isUserLoggedIn");
    dispatch(logoutAction());
    // navigate("/login"); // Redirect to login after logout using navigate
    window.location.href = "/";
  };

  return (
    // <button onClick={handleLogout} style={buttonstyle}>
    //   Logout
    // </button>
    <did></did>
  );
};

export default Logout;
