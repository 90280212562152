import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ItemList from "../Tasks/sub-components/ItemList";
import ItemComponent from "../Tasks/sub-components/ItemComponent";
import ActionPanel from "../Actions/ActionPanel";
import HashtagAndItemManager from "../Hashtags/sub-components/HashtagAndItemManager";
import Logout from "../Login/Logout";
import UserProfile from "../Login/UserProfile";
import { useSelector } from "react-redux";
import "./MobileMainComponent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { copyToClipboard } from "../../utils/clipboardUtils";
import "bootstrap-icons/font/bootstrap-icons.css";

function MobileMainComponent() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedHashtags, setSelectedHashtags] = useState(() => {
    const saved = localStorage.getItem("selectedHashtags");
    return saved
      ? JSON.parse(saved)
      : [{ _id: "uncategorized", tag: "Uncategorized" }];
  });
  const [refreshItemList, setRefreshItemList] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [dateComment, setDateComment] = useState("");
  const [items, setItems] = useState([]);
  // const [items, setItems] = useState([]); // Assuming this stores all tasks fetched initially
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const [currentSection, setCurrentSection] = useState("section1");

  useEffect(() => {
    const savedItem = localStorage.getItem("selectedItem");
    if (savedItem) {
      setSelectedItem(JSON.parse(savedItem));
    }
  }, []);
  useEffect(() => {
    if (selectedItem && selectedItem.itemId) {
      const fetchItem = async () => {
        try {
          const response = await fetch(`/api/items/${selectedItem.itemId}`);
          if (!response.ok) throw new Error("Failed to fetch item");
          const itemData = await response.json();
          setSelectedItem(itemData);
        } catch (error) {
          console.error("Error fetching item:", error);
        }
      };
      fetchItem();
    }
  }, [selectedItem?.itemId]);
  // Depend on selectedItem.itemId

  const handleItemClick = (item) => {
    console.log("Item clicked:", item);
    setSelectedItem(item);
    localStorage.setItem("selectedItem", JSON.stringify(item)); // Save to local storage
  };

  const handleNewItemAdded = () => {
    setRefreshItemList((prev) => !prev);
  };
  useEffect(() => {
    console.log("Updating local storage with hashtags:", selectedHashtags);
    localStorage.setItem("selectedHashtags", JSON.stringify(selectedHashtags));
  }, [selectedHashtags]);

  // Handle hashtag selection
  const handleHashtagSelection = (hashtags) => {
    console.log("Hashtags selected:", hashtags);
    console.log("Hashtags selected:", hashtags);
    setSelectedHashtags(hashtags);
  };

  const copyHashtagUrl = () => {
    const customIdUrlPart = selectedHashtags.map((ht) => ht.customId).join(",");
    copyToClipboard(`${window.location.origin}/hashtags/${customIdUrlPart}`)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset icon after 2 seconds
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  function getSavedHashtags() {
    const storedHashtags = localStorage.getItem("selectedHashtags");
    return storedHashtags ? JSON.parse(storedHashtags) : [];
  }

  const onItemUpdated = (updatedItem) => {
    setSelectedItem(updatedItem);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.itemId === updatedItem.itemId ? updatedItem : item
      )
    );
    setRefreshItemList((prev) => !prev);
  };
  function onPioneerClick(event) {
    if (selectedHashtags && selectedHashtags.length > 0) {
      event.preventDefault(); // Prevent the Link from navigating
      // You can also perform other logic here, if necessary
    }
  }

  const shareHashtags = () => {
    const customIdUrlPart = selectedHashtags.map((ht) => ht.customId).join(",");
    navigate(`/hashtags/${customIdUrlPart}`);
  };

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const sections = [
    <div className="HashtagPanel">
      <HashtagAndItemManager
        selectedHashtags={selectedHashtags}
        onItemAdded={handleNewItemAdded}
        setSelectedHashtags={handleHashtagSelection}
      />
    </div>,
    <ItemList
      onItemClick={handleItemClick}
      selectedItem={selectedItem}
      selectedHashtags={selectedHashtags}
      refreshTrigger={refreshItemList}
    />,

    <ItemComponent
      item={selectedItem}
      // refreshItem={refreshSelectedItem}
      onItemUpdated={onItemUpdated}
      dateComment={dateComment}
    />,
    <ActionPanel selectedItem={selectedItem} onItemUpdated={onItemUpdated} />,
    ,
  ];

  const nextSection = () => {
    setCurrentSectionIndex((prev) =>
      prev < sections.length - 1 ? prev + 1 : prev
    );
  };

  const previousSection = () => {
    setCurrentSectionIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  return (
    <>
      <div className="top-navbar">
        <Link to="/" onClick={onPioneerClick}>
          <div className="logo">
            <img src="/logo.png" alt="Logo" />
          </div>
        </Link>
        <Link to="/" className="app-title-link" onClick={onPioneerClick}>
          <div className="app-title">Pioneer</div>
        </Link>
        <div className="hashtag-section">
          <div className="hashtags-and-share">
            <div className="selected-hashtags">
              {Array.isArray(selectedHashtags) &&
                selectedHashtags.map((hashtag, index) => (
                  <p key={index}>{hashtag.tag}</p>
                ))}
            </div>
            <button className="share-button" onClick={shareHashtags}>
              <FontAwesomeIcon icon={faShare} />
            </button>
            <div>
              {" "}
              <button
                onClick={copyHashtagUrl}
                className="btn btn-outline-secondary copy-button"
                title="Copy URL to Clipboard"
              >
                <i
                  className={`bi ${
                    copied ? "bi-clipboard-check" : "bi-clipboard"
                  }`}
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div className="logout-button">
          <Logout />
        </div>
        <div>
          <UserProfile />{" "}
        </div>
      </div>
      <div className="mobile-layout">
        <div className="navigation-bar">
          <button onClick={previousSection}>&lt;</button>
          <button onClick={nextSection}>&gt;</button>
        </div>
        <div className="section-display">{sections[currentSectionIndex]}</div>
      </div>
    </>
  );
}

export default MobileMainComponent;
