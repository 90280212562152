import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/HashtagManager.css";
import * as HashtagService from "../services/HashtagService";

function HashtagList({ setSelectedHashtags }) {
  const uncategorizedHashtagId = process.env.REACT_APP_UNCATEGORIZED_HASHTAG_ID;
  const navigate = useNavigate();
  const { groupId } = useParams(); // Get the groupId from the URL params
  const [hashtags, setHashtags] = useState([]);
  const [uiSelectedHashtags, setUiSelectedHashtags] = useState([
    uncategorizedHashtagId,
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!uncategorizedHashtagId) {
      console.error(
        "Uncategorized hashtag ID not found in environment variables"
      );
    } else {
      console.log("Initial selected hashtag:", [uncategorizedHashtagId]);
    }
  }, [uncategorizedHashtagId]);

  useEffect(() => {
    setLoading(true);
    // Fetch hashtags by group ID
    HashtagService.fetchHashtagGroupById(groupId)
      .then((group) => {
        if (group && group.hashtags) {
          setHashtags(group.hashtags);
          console.log("Fetched hashtags for group:", group.hashtags);
        }
      })
      .catch((err) => {
        setError("Failed to fetch hashtags");
        console.error("Error fetching hashtags:", err);
      })
      .finally(() => setLoading(false));
  }, [groupId]);

  const handleSelectHashtag = (selectedHashtag) => {
    if (!selectedHashtag || typeof setSelectedHashtags !== "function") {
      console.error(
        "Invalid hashtag selected or setSelectedHashtags is not a function"
      );
      return;
    }

    setUiSelectedHashtags((prevSelected) => {
      const isSelected = prevSelected.includes(selectedHashtag._id);
      const newSelected = isSelected
        ? prevSelected.filter((id) => id !== selectedHashtag._id)
        : [
            ...prevSelected.filter((id) => id !== uncategorizedHashtagId),
            selectedHashtag._id,
          ];

      if (newSelected.length === 0) {
        newSelected.push(uncategorizedHashtagId);
      }

      setSelectedHashtags(newSelected);
      navigate(`/main/hashtag/${encodeURIComponent(selectedHashtag._id)}`);
      return newSelected;
    });
  };

  useEffect(() => {
    if (typeof setSelectedHashtags === "function") {
      setSelectedHashtags(uiSelectedHashtags);
    } else {
      console.error("setSelectedHashtags is not a function");
    }
  }, [uiSelectedHashtags, setSelectedHashtags]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="hashtag-manager-container">
      <h3>Hashtags in {groupId ? `Group ${groupId}` : "All Groups"}</h3>
      <ul className="hashtag-list">
        {hashtags.map((hashtag) => (
          <li
            key={hashtag?._id}
            className={`hashtag-item ${
              uiSelectedHashtags.some((selected) => selected?._id === hashtag?._id)
                ? "selected"
                : ""
            }`}
            onClick={() => handleSelectHashtag(hashtag)}
          >
            {hashtag?.tag}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HashtagList;
